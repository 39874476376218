// Would be nice if we could create a custom theme and use this primary color throughout.  Set it to the same variable as material themes, but didn't have enough time to dive into that.
// Used primarily in task list.


$mdc-theme-primary: #5b9acf; // made darker for 508/WCAG compliance
$base-grey: #f1f1f1;
$grey-label: #767676; /* min grey on white background to pass WCAG contrast test -- see https://webaim.org/articles/contrast/#sc143 */
// see design.max.gov

// BLUES
$max-blue: #0072c6;             // Changed to match Carl's recommendation, 5/7/2024
$max-dark-blue: #0e285d;        // Changed to match Carl's recommendation, 5/7/2024
$max-darkest-blue: #000030;     
$max-light-blue: #61a0d5;
$max-lightest-blue: #dae9f5;
$max-darker-blue: #112a40;      // As of May 2024, not used
$max-lighter-blue: #9ec4e5;     // As of May 2024, not used

// GRAYS
$max-dark-gray: #424242;
$max-lighter-gray: #dbdbdb;
$max-lightest-gray: #e8e8e8;
$max-gray: #757575;             // As of May 2024, not used
$max-darkest-gray: #1c1c1c;     // As of May 2024, not used
$max-darker-gray: #292929;      // As of May 2024, not used
$max-light-gray: #a8a8a8;       // As of May 2024, not used

