/* Set some basic padding to keep content from hitting the edges */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import 'bootstrap/dist/css/bootstrap.css'; 
@import "font-awesome/css/font-awesome.css";
@import "styles/_variables.scss";

/*// Would be nice if we could create a custom theme and use this primary color throughout.  Set it to the same variable as material themes, but didn't have enough time to dive into that.  Used primarily in task list.*/
/*$mdc-theme-primary: #5b9acf; // made darker for 508/WCAG compliance
$base-grey: #f1f1f1;
$grey-label: #767676;  min grey on white background to pass WCAG contrast test -- see https://webaim.org/articles/contrast/#sc143 
*/

html, body {
    height: 100%;
}

/* when focus outline should be supressed completely */
div:focus {
    outline: none !important;
}

/* when focus should be made apparent to the user for accessiblity */
a:focus, button:focus, mat-icon:focus, h1:focus {
    outline: 1px solid #CCC !important;
}

.cb-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    flex-flow: row;
    flex-flow: column;
    margin: 15px 0px 0px 15px;
}

.skipnav {
    background: transparent;
    color: #212121;
    left: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    top: -4.2rem;
    transition: all 0.2s ease-in-out;
    z-index: 100
}

.skipnav:focus {
    background: #fff;
    border: 2px solid $mdc-theme-primary;
    outline: 0;
    position: absolute;
    top: 0;
    margin-left: 5px;
    margin-top: 5px;
    transition: all 0.2s ease-in-out
}

.redborder {
    border: 2px solid red !important;
}
.mat-mdc-form-field-error {
    max-width: 150px;
}

// Define dialogue CSS classes.
.draggable-dialogue-header {
    //cursor: move; __<<ngThemingMigrationEscapedComment6>>__
    //cursor: url(images/grab.cur); __<<ngThemingMigrationEscapedComment7>>__
    cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
    cursor: -moz-grab; /* Firefox 1.5-26 */
    cursor: move; //grab; __<<ngThemingMigrationEscapedComment10>>__
}

//TEAMS-424: KLW - Ensure icons are completely centered 
.center-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    height: inherit;
    width: inherit;
}

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14 to 15
.mat-mdc-list-item-unscoped-content { 
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    box-sizing: border-box !important; 
    position: relative !important;
    height: inherit !important; 
}

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14 to 15
.mat-mdc-list-item-unscoped-content div { 
    text-wrap: auto;
    font-size: 15px;
}

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14 to 15
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected{
    --mdc-chip-label-text-color: black;
    --mdc-chip-with-trailing-icon-trailing-icon-color:: #e0e0e0;
}

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14 to 15
.mat-mdc-button>.mat-icon { 
    height: 36px !important;
    margin-top: 12px !important;
    width: 36px !important;
    font-size: 28px !important;
}

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14 to 15
.mat-mdc-option .mdc-list-item__primary-text {
    display: flex;
}

//TEAMS-424: KLW - Styling to allow dialog boxes to have new lines, have to do this at the styles level
.dialog-confirm {
    white-space: pre-line;
}

//TEAMS-424: KLW - Styling for the rounded checkboxes used in the tile and grid views
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox.rounded-checkbox > label > span.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin > span.mat-checkbox-frame,
mat-checkbox.rounded-checkbox > label > span.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin > span.mat-checkbox-background {
    border-width: 2px;
    border-radius: 50% !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
mat-checkbox.rounded-checkbox.mat-checkbox-indeterminate > label > span.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin > span.mat-checkbox-background,
mat-checkbox.rounded-checkbox.mat-checkbox-checked > label > span.mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin > span.mat-checkbox-background {
    background-color: rgb(238, 4, 16);
}


//TEAMS-424: KLW - Styling for the features on the about page
.right-aligned-header > .mat-content {
    justify-content: start;
}

.mat-content > mat-panel-title,
.mat-content > mat-panel-description {
    flex: 0 0 auto;
}

.panelDescWidth300 { //for About page
    position: absolute;
    left: 300px;
}

.panelDescWidth200 { //for Conditional Logic panel
    position: absolute;
    left: 200px;
}

//Dialog styling
//Kevin
.mat-mdc-dialog-actions.confirm-buttons {
    padding: 15px 30px 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.mat-mdc-dialog-actions.submit-cancel-buttons {
    justify-content: space-between;
}


// div.FormInstance.drag-hint-border,
// mat-icon.FormInstance.drag-border,
// div.Attachment.drag-hint-border,
// mat-icon.Attachment.drag-border {
//     border: none !important;
// }

// tr.FormInstance.drag-hint-border,
// tr.Attachment.drag-hint-border {
//     border: none !important;
// }
 

.drag-over-class {
    background-color: red !important;
}

.no-border {
    border: none !important;
}

//This is to remove the blue border that appears in the mat sidenav
mat-sidenav-content:focus {
    /*    outline: none !important;
    border: none !important;*/
}

//Kevin icon directive
.icon-svg {
    height: 100%;
    width: 100%;
}

//VNEXT-384: KLW - Implementing Autocomplete styling
.full-width {
    width: 100%;
}
​
.chip-list-wrapper {
    min-height: 3em;
}

.input-group {
    display: flex;
    align-items: center;
    align-content: center;
}

.input-styling {
    margin-top: 15px !important;
}

.regular-chip {
    background-color: white !important;
    color: black !important;
}

.remove-all-chip {
    background-color: red !important;
    color: white !important;
}

.field-label {
    font-size: 14pt !important;
    font-weight: bold !important;
    color: $max-dark-blue !important;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap.scss'; //VNEXT-1430: KLW - When upgrading Material from 14 to 15 the relative path no longer works to import the scss file, removed the ~  

//VNEXT-1430: KLW - Styling changes to account for upgrading Material from 13 to 14
mat-icon{
    width: 40px; 
}

app-import-grid-data>h1 {
    text-align: center;
}

//VNEXT-1430: KLW - Fix the filter shading
.k-input-inner {
    background: gainsboro;
}

//VNEXT-1430: KLW - Fix the icon positions in text fields in the property side bar
.mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix {
    padding: 0 20px 0 0;
}


//VNEXT-1430: KLW - Styling for NG Drag Drop

/* Draggable*/

// .drag-border {
//     border: #ff525b dashed 2px;
// }

.drag-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.drag-handle:active { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}

/* Droppable */

.drag-hint-border { 
    border: #3c763d dashed 2px; 
}

.drag-over-border { 
    border: #fbbc05 dashed 2px; 
}

.drag-transit { 
    border: #3500FF dashed 2px; 
}
